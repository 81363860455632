import DriverHeader from '@/components/card-headers/drivers/DriverHeader';
import DriverDetailsCard from '@/components/cards/drivers/DriverDetailsCard';
import DriverPointTransactionsCard from '@/components/cards/drivers/DriverPointTransactionsCard';
import DriverServiceCard from '@/components/cards/drivers/DriverServiceCard';
import DriverVehicleCard from '@/components/cards/drivers/DriverVehicleDetailCard';
import DriverDocumentsTable from '@/components/cards/drivers/DriverDocumentsCard';
import UserActivitiesCard from '@/components/cards/users/UserActivitiesCard';
import ErrorComponent from '@/components/common/ErrorComponent';
import { UUID_REGEX } from '@/constants/regexs';
import Driver from '@/interfaces/Driver';
import { FC, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { DriverPreferencesCard } from '@/components/cards/drivers/DriverPreferencesCard';
import DriverStatisticCards from '@/components/cards/drivers/DriverStatisticCards';
import DriverBlocksTable from '@/components/cards/drivers/DriverBlocksTable';
import DriverRidesTable from '@/components/cards/drivers/DriverRidesTable';
import DriverReferralsTable from '@/components/cards/drivers/DriverReferralsTable';
import { DriverOffersCard } from '@/components/cards/drivers/DriverOffersCard';
import { DriverLastCallCard } from '@/components/cards/drivers/DriverLastCallCard';

const DriverView: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [driver, setDriver] = useState<Driver>();

  if (!id || !UUID_REGEX.test(id)) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  return (
    <div>
      {driver && (
        <DriverHeader
          id={driver.id}
          bloquedProfile={driver.blockedProfile}
          showBlockActionBtn
        />
      )}
      <Row className="g-3 mb-3 ">
        <Col xxl={12}>
          <DriverDetailsCard id={id} card callBack={setDriver} />
        </Col>
        {driver && (
          <Col xxl={6}>
            <DriverStatisticCards driver={driver!} />
            <div className="mt-2">
              <UserActivitiesCard id={driver.user.id} />
            </div>
            <div className="mt-2">
              <DriverPreferencesCard id={driver.id} />
            </div>
            <div className="mt-2">
              <DriverOffersCard id={driver.id} />
            </div>
            <div className="mt-2">
              <DriverPointTransactionsCard id={driver.id} />
            </div>
            <div className="mt-2">
              <DriverReferralsTable id={driver.id} />
            </div>
          </Col>
        )}
        {driver && (
          <>
            <Col xxl={6}>
              <DriverLastCallCard driverId={driver.id} />
              <div className="mt-2">
                <DriverDocumentsTable id={driver.id} />
              </div>
              <div className="mt-2">
                <DriverVehicleCard id={driver.id} />
              </div>
              <div className="mt-2">
                <DriverBlocksTable id={driver.id} />
              </div>
              <div className="mt-2">
                <DriverServiceCard id={id} />
              </div>
            </Col>
          </>
        )}
        {driver && (
          <Col xxl={12}>
            <DriverRidesTable driverId={driver.id} />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default DriverView;
