import { DateTime } from 'luxon';
import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import csvtojson from 'csvtojson';
import { throttle } from 'lodash';
import { AnyObject } from '@/interfaces/Fetch';
import { RefObject, useCallback, useRef, useState } from 'react';

export const array_intersect = (a: any[], b: any[]): boolean => {
  const setB = new Set(b);
  return a.some(x => setB.has(x));
};

export const capitalize = (str: string | null) =>
  str && (str.charAt(0).toUpperCase() + str.slice(1)).replace(/-/g, ' ');

export const capitalizeString = (str: string) => str.split(' ').map(item => item.replace(item.charAt(0), item.charAt(0).toUpperCase())).join(' ');


export const splitText = (
  text: string | undefined | null,
  limit: number = 30
) => {
  if (!text) return;
  return text.length > limit ? text.slice(0, limit) + '…' : text;
};

export const pluralize = (word: string, count: number): string => {
  return count <= 1 ? word : `${word}s`;
};

export const createFormDataRequest = (body: { [key: string]: any }) => {
  const formData = new FormData();
  for (const key of Object.keys(body)) {
    const element = body[key];
    if (element instanceof FileList && element.length > 0) {
      formData.append(key, element[0]);
    } else if (element instanceof File) {
      formData.append(key, element);
    } else if (
      typeof element === 'string' ||
      typeof element === 'number' ||
      typeof element === 'boolean'
    ) {
      formData.append(key, element.toString());
    } else if (element instanceof Array && element.length > 0) {
      element.forEach((value, index) => {
        if (typeof value === 'object' && !('stream' in value)) {
          appendObjectToFormData(formData, `${key}[${index}]`, value);
        } else {
          formData.append(`${key}[${index}]`, value);
        }
      });
    } else if (typeof element === 'object') {
      appendObjectToFormData(formData, key, element);
    }
  }

  return formData;
};

function appendObjectToFormData(
  formData: FormData,
  rootKey: string,
  object: any
) {
  for (const key in object) {
    if (object[key] !== null) {
      if (object[key] instanceof File || object[key] instanceof DateTime) {
        formData.append(`${rootKey}['${key}']`, object[key]);
      } else if (typeof object[key] === 'object') {
        appendObjectToFormData(formData, `${rootKey}['${key}']`, object[key]);
      } else if (object[key] !== undefined) {
        formData.append(`${rootKey}['${key}']`, object[key]);
      }
    }
  }
}

/**
 * This hook can be used when using ref inside useCallbacks
 * 
 * Usage
 * ```ts
 * const [toggle, refCallback, myRef] = useRefWithCallback<HTMLSpanElement>();
 * const onClick = useCallback(() => {
    if (myRef.current) {
      myRef.current.scrollIntoView({ behavior: "smooth" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle]);
  return (<span ref={refCallback} />);
  ```
 * @returns 
 */
  export function useRefWithCallback<T extends any>(): [
    boolean,
    (node: any) => void,
    RefObject<T>
  ] {
    const ref = useRef<T | null>(null);
    const [toggle, setToggle] = useState(false);
    const refCallback = useCallback((node: T) => {
      ref.current = node;
      setToggle(val => !val);
    }, []);
  
    return [toggle, refCallback, ref];
  }

export const arrayMove = (
  array: any[],
  oldValue: any,
  condition: 'plus' | 'minus'
): any[] => {
  const indexOldValue = array.indexOf(oldValue);

  if (indexOldValue === -1 || (indexOldValue === 0 && condition === 'minus')) {
    return array;
  }

  const newArray = [...array];
  newArray[indexOldValue] =
    newArray[condition === 'plus' ? indexOldValue + 1 : indexOldValue - 1];
  newArray[condition === 'plus' ? indexOldValue + 1 : indexOldValue - 1] =
    oldValue;

  return newArray;
};

/*
 * @param {string} str
 * @param {number} n
 * @return {string}
 * @description Truncate string to n characters
 */
export const truncate = (str: string, size: number) => {
  return str.length > size ? str.slice(0, size) + '…' : str;
};

export const formatPlate = (plate: string) => {
  return plate.replace(/^([A-Z]{2})(\d{3})([A-Z]{2})$/, '$1-$2-$3');
};

//utils for rides
export function calculatePercentageChange(
  basePrice: number,
  finalPrice: number
) {
  return ((finalPrice - basePrice) / basePrice) * 100;
}

export function calculateFinalETA(
  driverArrivalTime: Date,
  rideCompletionTime: Date,
  initialDriverEta: number
) {
  const totalDurationInMilliseconds =
    rideCompletionTime.getTime() - driverArrivalTime.getTime();
  const totalDurationInMinutes = Math.floor(
    totalDurationInMilliseconds / (1000 * 60)
  );
  return initialDriverEta + totalDurationInMinutes;
}

export function calculateDistance(
  lattitude1: number,
  longittude1: number,
  lattitude2: number,
  longittude2: number
) {
  const toRadian = (n: number) => (n * Math.PI) / 180;

  let lat2 = lattitude2;
  let lon2 = longittude2;
  let lat1 = lattitude1;
  let lon1 = longittude1;

  let R = 6371;
  let x1 = lat2 - lat1;
  let dLat = toRadian(x1);
  let x2 = lon2 - lon1;
  let dLon = toRadian(x2);
  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadian(lat1)) *
      Math.cos(toRadian(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = R * c;
  let roundedDistance = d.toFixed(3);
  console.log('distance==?', d);
  return Number(roundedDistance);
}

export function calculateAcceptanceTime(
  createdAt: Date,
  driverAcceptedAt: Date
) {
  const timeDifference = driverAcceptedAt.getTime() - createdAt.getTime();
  const minutes = Math.floor(timeDifference / 60000);
  const seconds = Math.floor((timeDifference % 60000) / 1000);

  let formattedTime = '';
  if (minutes > 0) {
    formattedTime += `${minutes}m`;
  }
  if (seconds > 0 || minutes === 0) {
    formattedTime += `${seconds}s`;
  }
  return formattedTime;
}

export function calculatePickupDetails(
  initialEta: number,
  initialDistance: number
) {
  const etaHours = Math.floor(initialEta / 60);
  const etaMinutes = initialEta % 60;
  return `${etaHours}h ${etaMinutes}m / ${initialDistance}km`;
}
export const validateEmail = (email: string): string | undefined => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email) ? undefined : 'Email has an invalid format';
};

/**
 * Validates the given date to determine if the person is 18 years or older.
 *
 * @param {string} date - The date of birth in string format.
 * @return {boolean} Returns true if the person is 18 years or older, false otherwise.
 */
export const validateAge = (date: string): boolean => {
  return new Date().getFullYear() - new Date(date).getFullYear() >= 18;
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export async function readFile(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsText(file);
  });
}

export function calculateLevenshteinDistance(s1: string, s2: string): number {
  const len1 = s1.length;
  const len2 = s2.length;
  const matrix: number[][] = [];

  for (let i = 0; i <= len1; i++) {
    matrix[i] = [];
    matrix[i][0] = i;
  }

  for (let j = 0; j <= len2; j++) {
    matrix[0][j] = j;
  }

  for (let j = 1; j <= len2; j++) {
    for (let i = 1; i <= len1; i++) {
      if (s1[i - 1] === s2[j - 1]) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j] + 1,
          matrix[i][j - 1] + 1,
          matrix[i - 1][j - 1] + 1
        );
      }
    }
  }

  return matrix[len1][len2];
}

export function getMostProbableOccurrence(
  target: string,
  strings: string[]
): string | undefined {
  if (target.trim() === '') return undefined; // If target string is empty, return undefined

  let minDistance = Infinity;
  let mostProbableOccurrence: string | undefined;

  for (const str of strings) {
    if (str.length >= 3) {
      const commonSubstring =
        str.length >= target.length
          ? str.substring(0, target.length)
          : target.substring(0, str.length);
      const commonLetters = commonSubstring
        .split('')
        .filter(letter => target.includes(letter)).length;
      if (commonLetters >= 3) {
        const distance = calculateLevenshteinDistance(target, str);
        if (distance === 0) return str; // If exact match found, return immediately
        if (distance < minDistance) {
          minDistance = distance;
          mostProbableOccurrence = str;
        }
      }
    }
  }

  return mostProbableOccurrence;
}

export const parseCsv: (
  csv: string,
  delimiter?: string
) => Promise<Array<{ [key: string]: string }>> = async (
  csv,
  delimiter = ';'
) => {
  return csvtojson({
    delimiter,
    quote: '"',
    noheader: false, // Set this to true if the CSV file doesn't have a header row
  }).fromString(csv);
};

export function haveMinimumSiblings(arr1: any[], arr2: any[]): boolean {
  console.log(countDuplicateKeys(arr1, arr2));

  const minSiblingCount = 5;
  return countDuplicateKeys(arr1, arr2) > minSiblingCount;
}

function countDuplicateKeys(arr1: AnyObject[], arr2: AnyObject[]): number {
  const keys1 = new Set(arr1.flatMap(obj => Object.keys(obj)));
  const keys2 = new Set(arr2.flatMap(obj => Object.keys(obj)));

  let duplicates = 0;

  console.log(keys1, keys2);

  for (const key of keys1) {
    if (keys2.has(key)) {
      duplicates++;
    }
  }

  return duplicates;
}

export function calculatePercentageProgress(
  currentValue: number,
  endValue: number
): number {
  const percentage = (currentValue / endValue) * 100;
  return Math.min(100, Math.max(0, percentage)); // Ensure the percentage is within the range [0, 100]
}

/**
 * Throttles an async function in a way that can be awaited.
 * By default throttle doesn't return a promise for async functions unless it's invoking them immediately. See CUR-4769 for details.
 * @param func async function to throttle calls for.
 * @param wait same function as lodash.throttle's wait parameter.
 *             Call this function at most this often.
 * @returns a promise which will be resolved/ rejected only if the function is executed, with the result of the underlying call.
 */
export function asyncThrottle<F extends (...args: any[]) => Promise<any>>(
  func: F,
  wait?: number
) {
  const throttled = throttle((resolve, reject, args: Parameters<F>) => {
    func(...args)
      .then(resolve)
      .catch(reject);
  }, wait);
  return (...args: Parameters<F>): ReturnType<F> =>
    new Promise((resolve, reject) => {
      throttled(resolve, reject, args);
    }) as ReturnType<F>;
}

export function randomIntFromInterval(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}
export const replaceLastQueryParameterUrl = (
  url: string,
  newParameter: string
): string => {
  const [baseUrl, queryString] = url.split('?');
  if (!queryString) return `${baseUrl}?${newParameter}`;
  const queryParams = queryString.split('&');
  if (queryParams.length === 0) return `${baseUrl}?${newParameter}`;
  const lastParamIndex = queryParams.length - 1;
  queryParams[lastParamIndex] = newParameter;
  return `${baseUrl}?${queryParams.join('&')}`;
};

export function base64ToFile(base64Data: string, fileName: string): File {
  const byteString = atob(base64Data.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new File(
    [
      new Blob([ab], {
        type: base64Data.split(',')[0].split(':')[1].split(';')[0],
      }),
    ],
    fileName,
    { type: base64Data.split(',')[0].split(':')[1].split(';')[0] }
  );
}

export function getMimeTypeFromUrl(url: string): string {
  const extension = extractFileNameFromUrl(url).split('.')[1];

  console.log('extension', extension);

  const mimeTypes: { [key: string]: string } = {
    pdf: 'application/pdf',
    png: 'image/png',
    jpg: 'image/jpg',
    jpeg: 'image/jpeg',
  };

  return mimeTypes[extension];
}
export function extractFileNameFromUrl(url: string) {
  const parts = url.split('/');
  const lastPart = parts[parts.length - 1];
  const fileName = lastPart.split('?')[0]; // Remove query parameters if any
  return fileName;
}

export function encodeToBase64(url: string): string {
  const encodedString = Buffer.from(url).toString('base64');
  return encodedString;
}

// Function to decode a base64 string to URL
export function decodeFromBase64(base64String: string): string {
  const decodedString = Buffer.from(base64String, 'base64').toString('utf-8');
  return decodedString;
}

export function compareStrings(str1: string, str2: string): boolean {
  return (
    str1.substring(0, 3).toLowerCase() === str2.substring(0, 3).toLowerCase() ||
    str1.toLowerCase() !== str2.toLowerCase()
  );
}

export const filterProperties = (raw: object, unallowed: string[]): object => {
  return Object.keys(raw)
    .filter(key => !unallowed.includes(key))
    .reduce((obj, key) => {
      // @ts-ignore
      obj[key] = raw[key];
      return obj;
    }, {});
};

export function queryStringToObject(queryString: string): {
  [key: string]: string;
} {
  const params = new URLSearchParams(queryString);
  const queryParams: { [key: string]: string } = {};

  params.forEach((value, key) => {
    queryParams[key] = value;
  });

  return queryParams;
}

export const isValidURL = (url: string): boolean => {
  const URLRegExp: RegExp = new RegExp(
    'https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,4}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)'
  );

  return URLRegExp.test(url);
};

export function objectToQueryString(params: Record<string, any>, prefix: string = ''): string {
  return Object.keys(params)
    .map(key => {
      const value = params[key];
      const prefixedKey = prefix ? `${prefix}[${key}]` : key;

      if (value === undefined || value === null) {
        return '';
      }

      if (typeof value === 'object' && !Array.isArray(value)) {
        return objectToQueryString(value, prefixedKey);
      }

      if (Array.isArray(value)) {
        return value
          .map(val => `${encodeURIComponent(prefixedKey)}=${encodeURIComponent(val)}`)
          .join('&');
      }

      return `${encodeURIComponent(prefixedKey)}=${encodeURIComponent(value)}`;
    })
    .filter(Boolean)
    .join('&');
}

export const changeNoneToNullValue = (
  obj: Record<string, any>
): Record<string, any> => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      key,
      value === '' ? null : value,
    ])
  );
};
