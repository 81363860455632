import React, {
  ElementRef,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { toast, ToastContentProps } from 'react-toastify';
import { useSelector } from 'react-redux';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { DateTime } from 'luxon';
import { ColumnDef, TableState } from '@tanstack/react-table';
import { useDestroyUserMutation, useGetUsersQuery } from '@/services/users';
import { selectCurrentUser } from '@/services/slices/authSlice';
import {
  getColorFromLoginType,
  getColorFromRole,
  getGenderAlias,
  getReelLoginTypeName,
  getReelRoleName,
} from '@/helpers/UserHelpers';
import { capitalizeString, truncate } from '@/helpers/utils';
import IUser, { IUserFilters } from '@/interfaces/User';
import Avatar from '@/components/common/Avatar';
import ErrorComponent from '@/components/common/ErrorComponent';
import Flex from '@/components/common/Flex';
import Loader from '@/components/common/Loader';
import SoftBadge from '@/components/common/SoftBadge';
import UnknownBadge from '@/components/common/UnknownBadge';
import Table from '@/components/common/table/Table';
import AllUsersHeader from '@/components/table-headers/users/AllUsersHeader';
import { faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AreYouSurToContinueModal from '@/components/modals/AreYouSurToContinueModal';

const UsersView: FC = () => {
  const [usersData, setUsersData] = useState<IUser[]>([]);

  const DeleteModalRef =
    useRef<ElementRef<typeof AreYouSurToContinueModal>>(null);

  const user = useSelector(selectCurrentUser);
  const [state, setState] = useState<Partial<TableState> & IUserFilters>({
    pagination: {
      pageIndex: 0,
      pageSize: 150,
    },
    globalFilter: '',
  });

  const [destroyUserAsync] = useDestroyUserMutation();

  const { data, isLoading, isError, error } = useGetUsersQuery({
    ...state.pagination,
    query: state.globalFilter,
    roles: state.roles,
    ...(state.sorting?.[0] && {
      sortBy: state.sorting[0].id,
      sortDirection: state.sorting[0].desc ? 'desc' : 'asc',
    }),
  });

  const totalUsers = data?.meta?.total;

  useEffect(() => {
    if (data) {
      setUsersData(data.data || []);
    }
  }, [data]);

  const columns: ColumnDef<IUser>[] = useMemo(
    () => [
      {
        header: 'Actions',
        enableSorting: false,
        footer: props => props.column.id,
        accessorFn: ({ id }) => (
          <>
            <Link to={`/users/${id}`} className="btn btn-primary btn-sm">
              <FontAwesomeIcon icon={faEye} />
            </Link>
            <Link to={`/users/${id}/edit`} className="btn btn-info btn-sm ms-2">
              <FontAwesomeIcon icon={faEdit} />
            </Link>
            <Button
              variant="danger"
              size="sm"
              className="ms-2"
              onClick={() => DeleteModalRef.current?.showOrHiddenModal(id)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </>
        ),
        id: 'actions',
        cell: info => info.getValue(),
      },
      {
        header: '#',
        id: 'number',
        cell: info => {
          if (totalUsers === undefined) {
            return 'Loading...';
          }
          const pageIndex = info.table.getState().pagination.pageIndex;
          const pageSize = info.table.getState().pagination.pageSize;
          const rowIndex = info.row.index;
          const recordNumber = totalUsers - (pageIndex * pageSize + rowIndex);

          // Ensure record number is within valid range
          return recordNumber > 0
            ? recordNumber
            : totalUsers - rowIndex - pageIndex * pageSize;
        },
      },
      {
        header: 'User ID',
        footer: props => props.column.id,
        accessorFn: ({ id }) => (
          <Link to={`/users/${id}`}>{truncate(id, 10)}</Link>
        ),
        id: 'id',
        cell: info => info.getValue(),
      },
      {
        header: 'Registration\nDate & Time',
        footer: props => props.column.id,
        accessorFn: ({ createdAt }) => (
          <>
            {DateTime.fromISO(createdAt).toFormat('dd/MM/yyyy')}
            <p className="mb-0 text-500">
              {DateTime.fromISO(createdAt).toFormat('HH:mm')}
            </p>
          </>
        ),
        id: 'createdAt',
        cell: info => info.getValue(),
      },
      {
        header: 'First Name\nLast Name',
        footer: props => props.column.id,
        enableSorting: false,
        accessorFn: ({ lastname, id, firstname, avatar, gender }) => (
          <Link to={`/users/${id}`}>
            <Flex alignItems="center">
              {avatar && (
                <Avatar
                  src={avatar.link}
                  alt={`${firstname} Avatar`}
                  className="me-2"
                  size="2xl"
                />
              )}
              <p className="mb-0">
                {gender && <span>{getGenderAlias(gender)}</span>}{' '}
                <span>{capitalizeString(firstname)}</span>
                <br />
                <span>{capitalizeString(lastname)}</span>
              </p>
              {user?.id === id && <b className="ms-2">(moi)</b>}
            </Flex>
          </Link>
        ),
        id: 'fullname',
        cell: info => info.getValue(),
      },
      {
        enableSorting: false,
        header: 'Referred',
        footer: props => props.column.id,
        accessorFn: ({ referrer }) => {
          if (referrer) {
            if (referrer.referrerDriverProfile) {
              return (
                <>
                  <div>
                    <SoftBadge bg="success">YES</SoftBadge> {' / '}
                    <SoftBadge bg="#E6E6FA" textColor="#4B0082">
                      DRIVER
                    </SoftBadge>
                  </div>
                  <div>
                    <Link to={`/drivers/${referrer.referrerDriverProfile.id}`}>
                      {referrer.referrerDriverProfile.user.fullname}
                    </Link>
                  </div>
                </>
              );
            } else if (referrer.referrerUser) {
              return (
                <>
                  <div>
                    <SoftBadge bg="success">YES</SoftBadge> {' / '}
                    <SoftBadge bg="info">USER</SoftBadge>
                  </div>
                  <div>
                    <Link to={`/users/${referrer.referrerUser.id}`}>
                      {referrer.referrerUser.fullname}
                    </Link>
                  </div>
                </>
              );
            }
          }
          return <SoftBadge bg="danger">NO</SoftBadge>;
        },
        id: 'referrerUser',
        cell: info => info.getValue(),
      },
      {
        enableSorting: false,
        header: 'First Coupon',
        footer: props => props.column.id,
        accessorFn: ({ firstCoupon }) => {
          if (firstCoupon && firstCoupon.coupon) {
            if (firstCoupon.coupon.name) {
              return (
                <SoftBadge bg="#cddff7" textColor="#1b477e">
                  {firstCoupon.coupon.name}
                </SoftBadge>
              );
            } else {
              if (typeof firstCoupon.coupon.discountAmount === 'number') {
                return (
                  <SoftBadge bg="#ccffcc" textColor="#008000">
                    {`Reward / ${firstCoupon.coupon.discountAmount}%`}
                  </SoftBadge>
                );
              }
            }
          }
          return 'N/A';
        },
        id: 'firstCoupon',
        cell: info => info.getValue() || 'N/A',
      },
      {
        header: 'Type',
        footer: props => props.column.id,
        accessorFn: ({ role, driverProfile }) => (
          <div>
            <SoftBadge
              bg={getColorFromRole(role)}
              className={'my-2 text-xl-center'}
            >
              {getReelRoleName(role)}
            </SoftBadge>
            {driverProfile && (
              <SoftBadge bg="primary" className={'my-2 text-xl-center'}>
                Driver
              </SoftBadge>
            )}
          </div>
        ),
        id: 'role',
        cell: info => info.getValue(),
      },
      {
        enableSorting: false,
        header: 'Email\nPhone Number',
        footer: props => props.column.id,
        accessorFn: ({ email, phone }) => (
          <>
            {email.toLowerCase()}
            <p className="mb-0 text-500">
              <a
                href={`https://wa.me/${phone.replace(/[^0-9]/g, '')}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#007bff' }}
                className="text-decoration-none"
              >
                {phone}
              </a>
            </p>
          </>
        ),
        id: 'email',
        cell: info => info.getValue(),
      },
      {
        header: 'Last Online\nDate & Time',
        footer: props => props.column.id,
        accessorFn: ({ lastLoginAt }) =>
          lastLoginAt ? (
            <>
              {DateTime.fromISO(lastLoginAt).toFormat('dd/MM/yyyy')}
              <p className="mb-0 text-500">
                {DateTime.fromISO(lastLoginAt).toFormat('HH:mm')}
              </p>
            </>
          ) : (
            <UnknownBadge />
          ),
        id: 'lastLoginAt',
        cell: info => info.getValue(),
      },
      {
        header: 'Last Login Type',
        footer: props => props.column.id,
        accessorFn: ({ lastLoginType }) =>
          lastLoginType ? (
            <SoftBadge
              bg={getColorFromLoginType(lastLoginType)}
              className="text-xl-center"
            >
              {getReelLoginTypeName(lastLoginType)}
            </SoftBadge>
          ) : (
            <UnknownBadge />
          ),
        id: 'lastLoginType',
        cell: info => info.getValue(),
      },
      {
        enableSorting: false,
        header: '# Rides Done\n# Rides Asked',
        footer: props => props.column.id,
        accessorFn: ({ completedRidesCount, requestsCount }) => (
          <>
            {completedRidesCount}
            <p className="mb-0 text-500">{requestsCount}</p>
          </>
        ),
        id: 'completedRidesCount',
        cell: info => info.getValue(),
      },
      {
        enableSorting: false,
        header: 'Total € Spent',
        footer: props => props.column.id,
        accessorFn: ({ totalSpent }) => `${totalSpent ?? 0} €`,
        id: 'totalSpent',
        cell: info => info.getValue(),
      },
      {
        enableSorting: false,
        header: 'Av. Rating',
        footer: props => props.column.id,
        accessorFn: ({ averageRating }) =>
          averageRating ? averageRating : <UnknownBadge />,
        id: 'averageRating',
        cell: info => info.getValue(),
      },
      {
        enableSorting: false,
        header: 'Cancel rate',
        footer: props => props.column.id,
        accessorFn: ({ cancelRate }) =>
          cancelRate ? `${cancelRate}%` : <UnknownBadge />,
        id: 'cancelRate',
        cell: info => info.getValue(),
      },
      {
        enableSorting: false,
        header: '# Points',
        footer: props => props.column.id,
        accessorFn: ({ points }) => points,
        id: 'points',
        cell: info => info.getValue(),
      },
      {
        header: 'Referral Code',
        footer: props => props.column.id,
        accessorFn: ({ referralCode }) => (
          <p style={{ fontWeight: 'bold' }}>{referralCode}</p>
        ),
        id: 'referralCode',
        cell: info => info.getValue(),
      },
      {
        enableSorting: false,
        header: '# Ref',
        footer: props => props.column.id,
        accessorFn: ({ referredUsersCount }) => referredUsersCount,
        id: 'referredUsersCount',
        cell: info => info.getValue(),
      },
      {
        enableSorting: false,
        header: '# APP VERSION',
        footer: props => props.column.id,
        accessorFn: ({ appVersion }) => (
          <p className="mb-0 text-500">{appVersion}</p>
        ),
        id: 'completedRidesCount',
        cell: info => info.getValue(),
      },
      {
        enableSorting: false,
        header: '# APP OS',
        footer: props => props.column.id,
        accessorFn: ({ appOS }) => (
          <p className="mb-0 text-500">{appOS}</p>
        ),
        id: 'completedRidesCount',
        cell: info => info.getValue(),
      },
    ],
    [totalUsers, state.pagination?.pageIndex, state.pagination?.pageSize]
  );

  const onDelete = useCallback(
    (id: string) => {
      toast.promise(destroyUserAsync(id).unwrap(), {
        pending: 'Suppression du statut en cours ...',
        success: {
          render() {
            DeleteModalRef.current?.showOrHiddenModal();
            return (
              <p className="mb-0 text-center">
                Le statut à bien été supprimé 🤩
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            return 'Une erreur est survenue';
          },
        },
      });
    },
    [destroyUserAsync]
  );

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  }

  return (
    <div>
      <AreYouSurToContinueModal
        ref={DeleteModalRef}
        onContinue={onDelete}
        title="Souhaitez-vous vraiment supprimé l'utilisateur qui a pour id : {{id}} ?"
      />
      <Table
        HeaderComponent={AllUsersHeader}
        columns={columns}
        state={state}
        tableName="users"
        onStateChange={setState}
        data={data?.data}
        meta={data?.meta}
      />
    </div>
  );
};

export default UsersView;
