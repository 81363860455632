import { arrayMove } from '@/helpers/utils';
import { useBreakpoints } from '@/hooks/useBreakpoints';
import ITableProps, { ITableMethods } from '@/interfaces/Table';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import {
  Column,
  Header,
  TableState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import classNames from 'classnames';
import {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  Card,
  Col,
  Form,
  Offcanvas,
  Row,
  Table as RBTable,
} from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import Flex from '../Flex';
import IconButton from '../IconButton';
import TablePagination from './TablePagination';
import DraggableRow from './DraggableRow';
import { IFilters } from '@/interfaces/Fetch';

const Table: ForwardRefRenderFunction<ITableMethods, ITableProps> = (
  {
    columns,
    data = [],
    HeaderComponent,
    onStateChange,
    smallFilters = false,
    paddingRows = 'lg',
    tableName,
    onDndChange,
    state,
    meta = {
      total: 0,
      lastPage: 15,
    },
    FilterComponent,
  },
  ref
) => {
  const [enableOrdering, setEnableOrdering] = useState<boolean>(false);

  const triggerEnableOrdering = () => setEnableOrdering(old => !old);

  //* This is default state variables
  const defaultState = useRef<Partial<TableState> & IFilters>(state).current;
  const defaultColumnOrder = useRef<string[]>([]);
  const onChangeVisibilityDefaultState = useRef<{
    [key: string]: undefined;
  }>({}).current;

  //* This is for breakpoints
  const { breakpoints } = useBreakpoints();

  //* This is for show filter or not
  const [showFilters, setShowFilters] = useState(false);
  const handleTriggerFilter = () => setShowFilters(old => !old);

  //* This is table state
  const {
    getHeaderGroups,
    getRowModel,
    getSelectedRowModel,
    getCanNextPage,
    getAllLeafColumns,
    getCanPreviousPage,
    nextPage,
    previousPage,
    getPageCount,
    setGlobalFilter,
    setPageIndex,
    setPageSize,
    getState,
    setColumnOrder,
    setColumnVisibility,
    getVisibleLeafColumns,
  } = useReactTable({
    // @ts-ignore
    columns,
    data,
    state,
    // @ts-ignore
    onStateChange,
    pageCount: meta.lastPage,
    enableSorting: true,
    sortDescFirst: false,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
  });

  const notHiddenColumns = useRef<Column<any, unknown>[]>(
    getAllLeafColumns().filter(
      column => column.columnDef.enableHiding !== false
    )
  ).current;

  //* This if functions for local storage
  const setOrderToStorage = (newOrder: string[]) => {
    tableName &&
      localStorage.setItem(`${tableName}Order`, JSON.stringify(newOrder));
  };

  const setVisibilityToStorage = (newVisibility: {
    [key: string]: boolean | undefined;
  }) => {
    tableName &&
      localStorage.setItem(
        `${tableName}Visibility`,
        JSON.stringify(newVisibility)
      );
  };

  const resetColumnOrder = () => {
    if (defaultColumnOrder.current.length > 0) {
      setColumnOrder(defaultColumnOrder.current);
      tableName && localStorage.removeItem(`${tableName}Order`);
    }
  };

  //* This is for column visibility
  useEffect(() => {
    const defaultColumnVisibility =
      tableName && localStorage.getItem(`${tableName}Visibility`);

    if (defaultColumnVisibility) {
      const newColumnVisibility: { [key: string]: boolean } = JSON.parse(
        defaultColumnVisibility
      );
      const unchangeabeColumnsVisibility = defaultState.columnVisibility;
      const columnVisibility = {
        ...newColumnVisibility,
        ...unchangeabeColumnsVisibility,
      };

      const hiddenColumns = Object.entries(columnVisibility).map(
        ([key, value]) => (value ? undefined : key)
      );
      const ids = getVisibleLeafColumns()
        .map(element => element.id)
        .filter(
          element =>
            !hiddenColumns.includes(element) &&
            element !== 'actions' &&
            element !== 'id'
        );
      defaultColumnOrder.current = ['actions', 'id', ...ids];
      setColumnVisibility(columnVisibility);
    } else {
      const ids = getVisibleLeafColumns().map(element => element.id);
      defaultColumnOrder.current = ids;
    }

    if (defaultState.columnVisibility) {
      for (let key in defaultState.columnVisibility) {
        onChangeVisibilityDefaultState[key] = undefined;
      }
    }

    const localStorageColumnOrder =
      tableName && localStorage.getItem(`${tableName}Order`);
    if (localStorageColumnOrder) {
      const newColumnOrder = JSON.parse(localStorageColumnOrder);
      setColumnOrder(newColumnOrder);
    } else {
      resetColumnOrder();
    }
  }, [defaultState]);

  const reorderRow = (draggedRowIndex: number, targetRowIndex: number) =>
    onDndChange?.({
      // @ts-ignore
      fromId: data[draggedRowIndex].id,
      // @ts-ignore
      toId: data[targetRowIndex].id,
    });

  const onFiltersChange = (filters: { [key: string]: any }) => {
    onStateChange(old => {
      const result = {
        ...old,
        ...filters,
        pagination: {
          pageSize: old.pagination?.pageSize ?? 15,
          pageIndex: old.pagination?.pageIndex ?? 0,
        },
      };
      tableName &&
        localStorage.setItem(`${tableName}Filters`, JSON.stringify(result));
      return result;
    });
  };

  const onColumnVisibilityChange = (
    columnName: string,
    visibility: boolean
  ) => {
    const newVisibilityColumns = {
      ...state.columnVisibility,
      [columnName]: visibility,
      ...onChangeVisibilityDefaultState,
    };

    setColumnOrder(old => {
      const result = visibility
        ? [columnName, ...old]
        : old.filter(d => d !== columnName);
      setOrderToStorage(result);
      defaultColumnOrder.current = [
        'actions',
        'id',
        ...result.filter(element => element !== 'actions' && element !== 'id'),
      ];
      return result;
    });

    setVisibilityToStorage(newVisibilityColumns);
  };

  const onMoveHeaderToRight = (header: Header<any, unknown>) => {
    setColumnOrder(old => {
      const result = arrayMove(old, header.id, 'minus');
      setOrderToStorage(result);
      return result;
    });
  };

  const onMoveHeaderToLeft = (header: Header<any, unknown>) => {
    setColumnOrder(old => {
      const result = arrayMove(old, header.id, 'plus');
      setOrderToStorage(result);
      return result;
    });
  };

  useImperativeHandle(ref, () => ({
    getSelectedRowModel,
    getCanNextPage,
    getCanPreviousPage,
    nextPage,
    previousPage,
    getPageCount,
    setPageIndex,
  }));

  return (
    <Row className="gx-3">
      <Col xl={!!FilterComponent && !smallFilters ? 9 : 12}>
        <Card>
          <Card.Header className="border-bottom border-200 px-0">
            <HeaderComponent
              triggerEnableOrdering={triggerEnableOrdering}
              resetColumnOrder={resetColumnOrder}
              onQueryChange={setGlobalFilter}
              onStateChange={onStateChange}
              onColumnVisibilityChange={onColumnVisibilityChange}
              notHiddenColumns={notHiddenColumns}
              smallFilters={smallFilters}
              onShowFilters={
                !!FilterComponent ? handleTriggerFilter : undefined
              }
            />
          </Card.Header>
          <Card.Body className="p-0">
            <SimpleBar>
              <RBTable size="sm" className="fs--1 mb-0">
                <thead className="bg-light text-800 align-middle">
                  {getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header, index) => {
                        return (
                          <th
                            key={header.id}
                            colSpan={header.colSpan}
                            className="whitespace-nowrap"
                            {...header.getContext().column.columnDef.meta
                              ?.headerProps}
                          >
                            {header.isPlaceholder ? null : (
                              <>
                                <div
                                  {...{
                                    className: header.column.getCanSort()
                                      ? 'cursor-pointer select-none'
                                      : '',
                                    onClick:
                                      header.column.getToggleSortingHandler(),
                                    style: {
                                      whiteSpace: 'pre',
                                    },
                                  }}
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                  {header.column.getCanSort()
                                    ? {
                                        asc: <span className="sort asc" />,
                                        desc: <span className="sort desc" />,
                                      }[
                                        header.column.getIsSorted() as string
                                      ] ?? <span className="sort" />
                                    : null}
                                </div>
                                {header.column.getCanPin() &&
                                  enableOrdering && (
                                    <Flex>
                                      {header.column.getIsPinned() !==
                                      'left' ? (
                                        <IconButton
                                          variant="nolina-default"
                                          className="p-1 py-0 me-2"
                                          size="sm"
                                          onClick={() =>
                                            onMoveHeaderToRight(header)
                                          }
                                          icon={faChevronLeft}
                                        />
                                      ) : null}
                                      {header.column.getIsPinned() !==
                                      'right' ? (
                                        <IconButton
                                          className="p-1 py-0"
                                          size="sm"
                                          variant="nolina-default"
                                          onClick={() =>
                                            onMoveHeaderToLeft(header)
                                          }
                                          icon={faChevronRight}
                                        />
                                      ) : null}
                                    </Flex>
                                  )}
                              </>
                            )}
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {getRowModel().flatRows.map(row => {
                    return !!onDndChange ? (
                      <DraggableRow
                        key={row.id}
                        row={row}
                        reorderRow={reorderRow}
                      />
                    ) : (
                      <tr
                        key={row.id}
                        className="btn-reveal-trigger align-middle"
                        role="row"
                      >
                        {row.getVisibleCells().map((cell, index) => {
                          // write custom props here
                          return (
                            <td
                              key={cell.id}
                              role="cell"
                              {...cell.getContext().cell.column.columnDef.meta
                                ?.cellProps}
                              className={classNames(
                                {
                                  'white-space-nowrap ': true,
                                  'pe-6 py-3': paddingRows === 'lg',
                                  'pe-3 py-2': paddingRows === 'md',
                                  'pe-2': paddingRows === 'sm',
                                },
                                cell.getContext().cell.column.columnDef.meta
                                  ?.cellProps?.className
                              )}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </RBTable>
            </SimpleBar>
          </Card.Body>
          <Card.Footer className="d-flex justify-content-between">
            <div>
              <Form.Select
                onChange={e => setPageSize(Number(e.target.value))}
                defaultValue={
                  meta.total > (state.pagination?.pageSize ?? 0)
                    ? state.pagination?.pageSize ?? 0
                    : meta.total
                }
              >
                {meta.total > 15 && <option value={15}>15</option>}
                {meta.total > 25 && <option value={25}>25</option>}
                {meta.total > 50 && <option value={50}>50</option>}
                {meta.total > 15 && (
                  <option value={meta.total <= 150 ? meta.total : 150}>
                    {meta.total <= 150 ? meta.total : 150}
                  </option>
                )}
              </Form.Select>
            </div>
            <TablePagination
              canNextPage={getCanNextPage()}
              pageCount={getPageCount()}
              setPageIndex={e => {
                setPageIndex(e);
                onStateChange(old => {
                  const result = {
                    ...old,
                    pagination: {
                      ...old.pagination,
                      pageIndex: old.pagination?.pageIndex ?? 0,
                    },
                  } as TableState;
                  return result;
                });
              }}
              pageIndex={getState().pagination.pageIndex}
              canPreviousPage={getCanPreviousPage()}
              nextPage={nextPage}
              previousPage={previousPage}
            />
          </Card.Footer>
        </Card>
      </Col>
      {!!FilterComponent && (
        <Col xl={3}>
          {smallFilters || breakpoints.down('xl') ? (
            <Offcanvas
              show={showFilters}
              onHide={handleTriggerFilter}
              placement="end"
              className="dark__bg-card-dark"
            >
              <Offcanvas.Header closeButton className="bg-light">
                <h6 className="fs-0 mb-0 fw-semi-bold">Filtres</h6>
              </Offcanvas.Header>
              <FilterComponent
                onFiltersChange={onFiltersChange}
                filters={state}
              />
            </Offcanvas>
          ) : (
            <FilterComponent
              onFiltersChange={onFiltersChange}
              filters={state}
            />
          )}
        </Col>
      )}
    </Row>
  );
};

export default forwardRef(Table);
