import ErrorComponent from '@/components/common/ErrorComponent';
import Loader from '@/components/common/Loader';
import SoftBadge from '@/components/common/SoftBadge';
import StatisticsCard from '@/components/common/StatisticsCard';
import UnknownBadge from '@/components/common/UnknownBadge';
import StoreUserPointTransactionModal from '@/components/modals/user/pointsTransactions/StoreUserPointTransactionModal';
import {
  getColorFromLoginType,
  getColorFromRole,
  getGenderAlias,
  getReelLoginTypeName,
  getReelRoleName,
} from '@/helpers/UserHelpers';
import { capitalizeString } from '@/helpers/utils';
import { ICardDetailsProps } from '@/interfaces/Card';
import IUser from '@/interfaces/User';
import {
  selectCurrentUser,
  selectCurrentUserConfig,
} from '@/services/slices/authSlice';
import { useGetUserQuery } from '@/services/users';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { ElementRef, FC, useEffect, useRef } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const UserDetailsCard: FC<ICardDetailsProps<IUser>> = ({
  id,
  callBack,
  errorCallBack,
}) => {
  const user = useSelector(selectCurrentUser);
  const userConfig = useSelector(selectCurrentUserConfig);
  const { data, isLoading, isError, error } = useGetUserQuery(id);
  const StoreUserPointTransactionModalModalRef =
    useRef<ElementRef<typeof StoreUserPointTransactionModal>>(null);

  const openCreateModal = () =>
    StoreUserPointTransactionModalModalRef.current?.showOrHiddenModal();

  useEffect(() => {
    data && callBack?.(data);
  }, [data]);

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} callback={errorCallBack} />;
  }

  return (
    <>
      {userConfig.isAdminOrSuperAdmin && (
        <StoreUserPointTransactionModal
          id={id}
          ref={StoreUserPointTransactionModalModalRef}
        />
      )}
      <Row className="g-3">
        <Col xs={12}>
          <Card className="font-sans-serif">
            <Card.Body>
              <Row className="gx-3">
                <Col xs={12} md={6} className="mb-3 mb-md-0">
                  <Table borderless className="fs--1 fw-medium mb-0">
                    <tbody>
                      <tr>
                        <td className="p-1" style={{ width: '35%' }}>
                          Fullname :
                        </td>
                        <td className="p-1 text-600">
                          {data?.gender && getGenderAlias(data?.gender)}{' '}
                          {capitalizeString(data!.fullname)}
                          {data!.id === user?.id && <b> (moi)</b>}
                        </td>
                      </tr>
                      <tr>
                        <td className="p-1" style={{ width: '35%' }}>
                          Last Online :
                        </td>
                        <td className="p-1 text-600">
                          {data?.lastLoginAt ? (
                            DateTime.fromISO(data!.lastLoginAt).toRelative()
                          ) : (
                            <UnknownBadge />
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="p-1" style={{ width: '35%' }}>
                          Joined :
                        </td>
                        <td className="p-1 text-600">
                          {DateTime.fromISO(data!.createdAt).toFormat(
                            "dd LLL yyyy 'at' HH:mm"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="p-1" style={{ width: '35%' }}>
                          Email :
                        </td>
                        <td className="p-1">
                          <Link
                            to={`mailto:${data!.email}`}
                            className="text-600"
                          >
                            {data!.email}
                          </Link>
                          <SoftBadge
                            bg="success"
                            pill
                            className="d-none d-md-inline-block ms-2"
                          >
                            Verified
                            <FontAwesomeIcon icon={faCheck} className="ms-1" />
                          </SoftBadge>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-1" style={{ width: '35%' }}>
                          Mobile No :
                        </td>
                        <td className="p-1">
                          <a
                            href={`https://wa.me/${data?.phone.replace(
                              /[^0-9]/g,
                              ''
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: '#007bff' }}
                            className="text-decoration-none"
                          >
                            {data?.phone}
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-1" style={{ width: '35%' }}>
                          Last Login Type :
                        </td>
                        <td className="p-1">
                          {data!.lastLoginType ? (
                            <SoftBadge
                              bg={getColorFromLoginType(data!.lastLoginType)}
                            >
                              {getReelLoginTypeName(data!.lastLoginType)}
                            </SoftBadge>
                          ) : (
                            <UnknownBadge />
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td className="p-1" style={{ width: '35%' }}>
                          Referral Code :
                        </td>
                        <td className="p-1">{data!.referralCode}</td>
                      </tr>

                      <tr>
                        <td className="p-1" style={{ width: '35%' }}>
                          Stripe ID :
                        </td>
                        <td className="p-1">
                          {data?.stripeId ? data!.stripeId : <UnknownBadge />}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col xs={12} md={6} className="mb-3 mb-sm-0">
                  <Table borderless className="fs--1 fw-medium mb-0">
                    <tbody>
                      <tr>
                        <td className="p-1" style={{ width: '35%' }}>
                          # Rides :
                        </td>
                        <td className="p-1">{data!.completedRidesCount}</td>
                      </tr>

                      <tr>
                        <td className="p-1" style={{ width: '35%' }}>
                          Role :
                        </td>
                        <td className="p-1">
                          {data!.lastLoginType ? (
                            <SoftBadge bg={getColorFromRole(data!.role)}>
                              {getReelRoleName(data!.role)}
                            </SoftBadge>
                          ) : (
                            <UnknownBadge />
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td className="p-1" style={{ width: '35%' }}>
                          € Spent :
                        </td>
                        <td className="p-1 text-600">
                          {data!.totalSpent ?? 0} €
                        </td>
                      </tr>
                      <tr>
                        <td className="p-1" style={{ width: '35%' }}>
                          Average Rating :
                        </td>
                        <td className="p-1 text-600">
                          {data!.averageRating ? (
                            data!.averageRating
                          ) : (
                            <UnknownBadge />
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="p-1" style={{ width: '35%' }}>
                          Cancellation rate :
                        </td>
                        <td className="p-1">
                          {data!.cancelRate ? (
                            `${data!.cancelRate}%`
                          ) : (
                            <UnknownBadge />
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="p-1" style={{ width: '35%' }}>
                          # Gov. Votes :
                        </td>
                        <td className="p-1">
                          {data!.governanceVotesCount ? (
                            `${data!.governanceVotesCount}2`
                          ) : (
                            <UnknownBadge />
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="p-1" style={{ width: '35%' }}>
                          Referred ?
                        </td>
                        <td className="p-1">
                          {data!.referrer ? (
                            <>
                              <SoftBadge bg="success">YES</SoftBadge> {' / '}
                              {data!.referrer.referrerDriverProfile ? (
                                <>
                                  <Link
                                    to={`/drivers/${
                                      data!.referrer.referrerDriverProfile.id
                                    }`}
                                  >
                                    {
                                      data!.referrer.referrerDriverProfile.user
                                        .fullname
                                    }
                                  </Link>
                                  {' / DRIVER'}{' '}
                                </>
                              ) : (
                                <>
                                  <Link
                                    to={`/users/${
                                      data!.referrer?.referrerUser?.id
                                    }`}
                                  >
                                    {data!.referrer?.referrerUser?.fullname}
                                  </Link>
                                  {' / USER'}{' '}
                                </>
                              )}
                            </>
                          ) : (
                            <p className="mr-4">
                              <SoftBadge bg="danger">NO</SoftBadge>
                            </p>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="p-1" style={{ width: '35%' }}>
                          App version
                        </td>
                        <td className="p-1">{data?.appVersion}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <StatisticsCard
            title="Points"
            value={data!.points}
            prefix="⚡"
            badgeBg="success"
            linkText="Add transaction"
            onClick={openCreateModal}
          />
        </Col>
        <Col md={3}>
          <StatisticsCard
            title="# Ref"
            value={data!.referredUsersCount ?? 0}
            prefix="🔗 "
            badgeBg="primary"
            linkText="See all"
            link={`/users/${data!.id}/points`}
          />
        </Col>
        <Col md={3}>
          <StatisticsCard
            title="Gov. Votes"
            value={data!.governanceVotesCount}
            prefix="🗳️ "
            badgeBg="warning"
            linkText="See all"
            link={`/users/${data!.id}/points`}
          />
        </Col>
        <Col md={3}>
          <StatisticsCard
            title="Rides"
            value={data!.completedRidesCount}
            prefix="🚗 "
            badgeBg="info"
            linkText="All rides"
            link={`/users/${data!.id}/points`}
          />
        </Col>
      </Row>
    </>
  );
};

export default UserDetailsCard;
